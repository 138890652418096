import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState,Dispatch,SetStateAction } from "react";
import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import { Permissions } from "../../types/User";
import { Loan } from "../../types/General";
import moment from "moment";
import { LoanAPi } from "../../services/loans";
import WarnModal from "../../components/WarnModal";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import Pagination from "../../components/Pagination";
import { isValidInput } from "../../utils/validations";

type props = {
  hidePermission: Permissions | null | undefined;
  data: Loan[];
  setData: Dispatch<SetStateAction<Loan[]>>;
  value: number;
  setValue:Dispatch<SetStateAction<number>>;

  debouncedSearchTerm: string;
  searchTerm: string;
  page: number;
  totalPages: number;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;

  onPageChange: (newPage: number) => void;
  fetchLoan: () => void;
};

const Completed = ({
  hidePermission,
  data,
  setData,
  value,
  setValue,
  page,
  totalPages,
  onPageChange,
  fetchLoan,
  searchTerm,setDebouncedSearchTerm,debouncedSearchTerm,setSearchTerm
}:props) => {
  const navigate = useNavigate();
  //States
  const [open,setOpen]=useState<boolean>(false)
  const [selectedId,setSelectedId]=useState<string>("")

  //API Hooks
  const [deleteById]=LoanAPi.useLazyDeleteLoanQuery()
  const [updateLoan]=LoanAPi.useUpdateLoanMutation()


  
  const label = { inputProps: { "aria-label": "Switch demo" } };
  

  return (
    <div>
      <Box className="cards_header">
      <SearchBar
          searchTerm={searchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          value={searchTerm}
          onCross={() => setSearchTerm("")}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center"> User Details</TableCell>
              <TableCell align="center">Car name</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Price</TableCell>
              
              <TableCell align="center"> Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          {data?.length?(
            data?.map((item,index)=>(
              <TableBody>
                <TableRow>
                <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                  <TableCell align="center">{moment(item?.createdAt).format("DD/MM/YYYY")||"--/--/----"}</TableCell>
                  <TableCell align="center">
                    <p>{item?.userEmail || "--"}</p>
                    <p> {(item?.userFullName || "-")}</p>
                  </TableCell>
                  <TableCell align="center">{item?.vehicleName || "--"}</TableCell>
                  <TableCell align="center">{item?.categoryName || "--"}</TableCell>
                  <TableCell align="center">${item?.vehiclePrice || "--"}</TableCell>
                  <TableCell>
                <Switch {...label} checked={!item?.isBlocked} size="small" onClick={()=>{
                  updateStatus(
                    item?._id,
                    !item?.isBlocked,
                    updateLoan,
                    fetchLoan
                    
                  )
                }} />
              </TableCell>
              <TableCell>
                <Box className="table_actions">
                  <Tooltip title="view">

                  <IconButton
                    onClick={() =>
                      navigate("/manage-loans/details/"+item?._id, {
                        state: { userStatus: "ACCEPTED" },
                      })
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title="delete">

                  <IconButton onClick={()=>{
                    setOpen(true)
                    setSelectedId(item?._id)
                  }}>
                    <DeleteIcon />
                  </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
                </TableRow>
              </TableBody>

            ))
          ):(
            <TableBody>
            <TableCell
              align="center"
              colSpan={10}
              sx={{ color: "#051140" }}
            >
              No Loan Found
            </TableCell>
          </TableBody>
          )}

          
        </Table>
      </TableContainer>
      <WarnModal
      name="loan"
      open={open}
      setOpen={setOpen}
      handleDelete={()=>{
        handleDelete(deleteById,selectedId,fetchLoan)
      }}/>
      <Pagination
      module={data}
      page={page}
      onPageChange={onPageChange}
      totalPages={totalPages}/>
    </div>
  );
};

export default Completed;
